import { TextsProps } from "../interfaces/TextsInterfaces";


export const flexServicesTexts: TextsProps = {
    about: {
        title: '¿En qué consiste el servicio?',
        description: `Podrás hacer envíos urgentes con entrega en el mismo 
        día o en el día hábil siguiente según el horario de creación del envío, 
        a través de los operadores que admiten este servicio.`,
        cards: [
            {
                id: 0,
                title: 'Envíos a domicilio',
                description: 'Enviamos a todo AMBA de forma segura y rápida.',
                image: require('../images/casa.webp')
            },
            {
                id: 1,
                title: 'Almacenamiento',
                description: 'Nos encargamos de gestión del stock, armado y despacho de los productos.',
                image: require('../images/deposito.webp')
            },
            {
                id: 2,
                title: 'Recolecciones',
                description: 'Pasamos por local o depósito para buscar los pedidos y luego enviarlo.',
                image: require('../images/camion.webp')
            }
        ]
    },
    operation: {
        title: '¿Cómo funciona?',
        cards: [
            {
                id: 0,
                title: 'Automatizá tus ventas',
                description: 'Delegá la parte más difícil de tu negocio. Nosotros somos la solución para toda tu logística.',
            },
            {
                id: 1,
                title: 'Control de tu stock',
                description: 'Si queres, podes almacenar tus productos en nuestro depósito donde nos encargamos del armado y envío.',
            },
            {
                id: 2,
                title: 'Aguarda la recolección y entrega',
                description: 'El transporte elegido recolectará y entregará tus despachos.',
            }
        ]
    },
    coverage: {
        title: 'Zona de cobertura',
        image: require('../images/map.png')
    },
    price: {
        title: 'Precio',
        subtitle: 'Envíos Flex',
        image: require('../images/caja2.webp'),
        oldPrice: '$5.994',
        newPrice: '$2.987',
        offer: '50%',
    }
}

export const epackServicesTexts: TextsProps = {
    about: {
        title: '¿En qué consiste el servicio?',
        description: `Podrás delegar y automatizar los envíos de Correo Argentino, Andreani, OCA, Vía Cargo.`,
        cards: [
            {
                id: 0,
                title: 'Recepción',
                description: 'Almacenamiento y gestión del stock a tiempo real.',
                image: require('../images/recepcion.webp')
            },
            {
                id: 1,
                title: 'Picking y packing',
                description: 'Preparamos tus paquetes para enviarlos.',
                image: require('../images/packing.webp')
            },
            {
                id: 2,
                title: 'Distribución',
                description: 'Distribuimos tus paquetes a las empresas nacionales. También gestionamos los cambios o devoluciones que tengas con tus clientes.',
                image: require('../images/repartidor.webp')
            }
        ]
    },
    coverage: {
        title: 'Zona de cobertura',
        image: require('../images/arg.webp')
    },
    price: {
        title: 'Precio',
        subtitle: 'E-paquetería premium',
        image: require('../images/caja4.webp'),
        oldPrice: '$3.994',
        newPrice: '$1.897',
        offer: '50%',
    }
}