import { useEffect, useState } from "react"
import { ContactForm } from "../components/ContactForm"
import { Header } from "../components/Header"
import { Rights } from "../components/Rights"
import { Preloader } from "../components/Preloader"
import { scrollFadeInCenter, scrollFadeInFastCenter, scrollFadeInFastLeft, scrollFadeInFastRight, scrollFadeInFastTop, scrollFadeInLeft, scrollFadeInRight, scrollFadeInTop } from "../effects/Observers"
import { WsFloatButton } from "../components/WsFloatButton"
import { Coverage } from "../components/Coverage"
import { useLocation } from "react-router-dom"
import { useAnimations } from "../effects/Effects"
import { ServiceAbout } from "../components/ServiceAbout"
import { ServiceOperation } from "../components/ServiceOperation"
import { ServicePrice } from "../components/ServicePrice"
import { epackServicesTexts, flexServicesTexts } from "../constants/ServiceConstants"
import { TextsProps } from "../interfaces/TextsInterfaces"

export const ServiceScreen = () => {

    const location = useLocation();

    const [showPreloader, setShowPreloader] = useState(true);

    const [serviceContent, setServiceContent] = useState<TextsProps>(
        location.pathname === '/flex' ? flexServicesTexts : epackServicesTexts);

    const { handleClickGoHomeInstant } = useAnimations()

    const handleClick = () => {

        setTimeout(() => {
            setShowPreloader(false);
        }, 2900);

    };

    useEffect(() => {

        const setTextContent = (pathname: string) => {

            if (pathname === '/flex') {

                setServiceContent(flexServicesTexts);

            } else {

                setServiceContent(epackServicesTexts);

            }

        }

        handleClickGoHomeInstant()

        setTextContent(location.pathname);

        handleClick()

        scrollFadeInTop();
        scrollFadeInFastTop();

        scrollFadeInCenter();
        scrollFadeInFastCenter();

        scrollFadeInRight();
        scrollFadeInFastRight();

        scrollFadeInLeft();
        scrollFadeInFastLeft();


    }, [location.pathname])

    return (

        <div className='home__main-content'>

            {showPreloader && <Preloader />}

            <WsFloatButton />

            <Header />

            {/* Porque elegirnos */}

            <ServiceAbout content={serviceContent.about} />

            {/* Como funciona */}

            {serviceContent.operation && <ServiceOperation content={serviceContent.operation} />}

            {/* Zona de cobertura */}

            <Coverage content={serviceContent.coverage} />

            {/* Precio */}

            {/* <ServicePrice content={serviceContent.price} /> */}

            {/* Contacto */}

            <ContactForm />

            {/* Derechos */}

            <Rights />

        </div>

    )
}
