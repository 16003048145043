import styled, { css } from "styled-components";
import { device } from "../styles/stylesConfig";
import { useAnimations } from "../effects/Effects";
import { useHistory, useLocation } from "react-router-dom";
import { useLogEvent } from "../hooks/useLogEvent";
import { globalTexts } from "../constants/GlobalConstants";

const MenuContainer = styled.div<{ show: string }>`
    width: 100%;
    display: flex;
    color: white;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 1;
    visibility: hidden;

    @media ${device.lg} {
        visibility: visible;
    }

    @keyframes openModal {
        0% {
            height: 0vh;
            background: rgba(0,0,0,0);
        }
        100% {
            height: 100vh;
            background: rgba(0,0,0,0.9);
        }
    }

    @keyframes closeModal {
        0% {
            height: 100vh;
            background: rgba(0,0,0,0.9);
        }
        100% {
            height: 0vh;
            background: rgba(0,0,0,0);
        }
    }

    @keyframes closeText {
        0% {
            font-size: 28px;
            @media ${device.xs} {
                font-size: 20px;
            }
        }
        100% {
            font-size: 0px;
        }
    }

    ${({ show }) => show === 'false' && css`

        & > div {
            font-size: 0px;
            animation: closeText 0.2s normal;
            visibility: hidden;
        }

        height: 0vh;
        animation: closeModal 0.3s normal;


    `}

    ${({ show }) => show === 'true' && css`

        height: 100vh;
        animation: openModal 0.3s normal;
        background: rgba(0,0,0,0.9);

    `}
`

const NavButton = styled.div`
    color: white;
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 5px;
    padding: 15px 0px;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease;
    &:hover {
        color: #B8D279;
        background-color: rgba(255,255,255,0.1);
    }

    @media ${device.sm} {
        font-size: 20px;
    }

`

const WhatsAppButton = styled.button<{ show: string }>`
    letter-spacing: 1.4px;
    border: solid 1px transparent;
    cursor: pointer;
    padding: 15px 20px;
    background-color: #4baf51;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 50px;
    transition: all .2s ease;
    margin-top: 15px;

    &:hover {
        box-shadow: 0px 0px 20px 0px  rgba(255, 255, 255, 0.2);
        background-color: #54c35c;
        border-color: white;
    }

    ${({ show }) => show === 'false' && css`

        visibility: hidden;
        animation: visibility 0.2s normal, closeText 0.2s normal;

    `}

    ${({ show }) => show === 'true' && css`

        visibility: visible;
        animation: visibility 0.2s normal;

    `}

`

interface Props {
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Menu = ({ showMenu, setShowMenu }: Props) => {

    const {
        handleClickGoHome,
        handleClickGoServices,
        handleClickGoAbout,
        handleClickGoSocial,
        handleClickGoPrices
    } = useAnimations()

    const history: any = useHistory();

    const location = useLocation();

    const { handleLogEvent } = useLogEvent();

    return (

        <MenuContainer show={showMenu.toString()}>

            <NavButton
                onClick={async () => {
                    setShowMenu(false);

                    if (location.pathname !== "/") {
                        await history.push('/');

                        setTimeout(() => {
                            handleClickGoHome()
                        }, 1000);
                    } else {
                        handleClickGoHome();
                    }

                    handleLogEvent('click_boton_inicio_menu');
                }}
            >
                Inicio
            </NavButton>

            <NavButton
                onClick={async () => {
                    setShowMenu(false);

                    if (location.pathname !== "/") {
                        await history.push('/');

                        setTimeout(() => {
                            handleClickGoServices()
                        }, 1000);
                    } else {
                        handleClickGoServices();
                    }

                    handleLogEvent('click_boton_servicios_menu');
                }}
            >
                Servicios
            </NavButton>

            <NavButton
                onClick={async () => {
                    setShowMenu(false);

                    if (location.pathname !== "/") {
                        await history.push('/');

                        setTimeout(() => {
                            handleClickGoPrices()
                        }, 1000);

                    } else {
                        handleClickGoPrices();
                    }

                    handleLogEvent('click_boton_precios_menu');
                }}
            >
                Precios
            </NavButton>

            <NavButton
                onClick={async () => {
                    setShowMenu(false);

                    if (location.pathname !== '/jobs') {

                        history.push('/jobs');

                        setTimeout(() => {
                            handleClickGoHome()
                        }, 1000);

                    } else {
                        handleClickGoHome()

                    }

                    handleLogEvent('click_boton_trabaja_con_nosotros_menu');
                }}
            >
                Trabajemos juntos
            </NavButton>

            <NavButton
                onClick={async () => {
                    setShowMenu(false);
                    if (location.pathname !== "/") {
                        await history.push('/');

                        setTimeout(() => {
                            handleClickGoSocial()
                        }, 1000);

                    } else {
                        handleClickGoSocial()
                    }

                    handleLogEvent('click_boton_contacto_menu');
                }}
            >
                Contacto
            </NavButton>

            <WhatsAppButton show={showMenu.toString()} onClick={async () => {

                window.open(globalTexts.whatsapp, '_blank');

                handleLogEvent('click_boton_whatsapp_menu');

            }}>IR A WhatsApp</WhatsAppButton>

        </MenuContainer>

    )
}
