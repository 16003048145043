import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useHistory } from "react-router-dom"
import { useLogEvent } from "../hooks/useLogEvent"
import { useAnimations } from "../effects/Effects"
import { globalTexts } from "../constants/GlobalConstants"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 100px 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 100px;

    h1 {
        font-size: 42px;
        color: #151515;
        letter-spacing: 5px;
    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -30px;
        right: 0;
        margin: auto;
        width: 80px;
        height: 3px;
        border-radius: 50px;
        background-color: #5fda67;
    }

    @media ${device.md} {

        h1 {
            font-size: 32px;
        }

    }

    @media ${device.sm} {
        margin-bottom: 80px;

        h1 {
            font-size: 26px;
        }
    }

    @media ${device.xs} {

        h1 {
            font-size: 20px;
        }

        h1::before {
            /* top: 50px; */
            width: 40px;
        }
    }

`

const InfoContainer = styled.div`
    width: fit-content;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-area: center;
    gap: 70px;

    @media ${device.lg} {
        gap: 50px;
    }

    @media ${device.md} {
        gap: 30px;
    }

    @media ${device.sm} {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        row-gap: 30px;
    }
`

const Card = styled.div`
    width: 450px;
    height: 450px;
    background: url(${require('../images/caja2.webp')}) center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    padding: 30px;
    border: 1px solid #f8f9fa;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    &:hover{

        button {
            transition: all .3s ease;
            box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.2);
            background-color: rgba(0, 0, 0, 0.2);
            border: 1px solid white;
        }
    }

    &::before {
        content: "";
        position: absolute;
        margin: auto;
        width: 100%;
        top: 0;
        height: 100%;
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.6);
    }

    &:last-of-type {
        background: url(${require('../images/caja3.webp')}) center no-repeat;
        background-size: cover;
    }

    h2 {
        font-size: 32px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 50px;
        z-index: 1;
        text-transform: uppercase;
    }

    p {
        font-size: 16px;
        color: #fff;
        text-align: center;
        z-index: 1;
        width: 80%;
        line-height: 30px;
        margin-bottom: 30px;
    }

    button {
        font-size: 14px;
        color: #fff;
        z-index: 1;
        text-transform: uppercase;
        padding: 15px 25px;
        border-radius: 50px;
        border: 1px solid white;
        backdrop-filter: blur(100px);
        cursor: pointer;

    }

    @media ${device.lg} {
        width: 300px;
        height: 360px;
        padding: 50px 20px;
        justify-content: space-between;

        h2 {
            font-size: 20px;
        }

        p {
            font-size: 14px;
            width: 90%;
        }

        button {
            font-size: 12px;
        }
    }

    @media ${device.md} {
        width: 220px;

        h2 {
            font-size: 20px;
            text-align: center;
        }

        p {
            font-size: 14px;
            width: 100%;
        }

        button {
            font-size: 12px;
        }
    }

    @media ${device.sm} {
        width: 300px;
        height: 240px;
        padding: 50px 20px;
        justify-content: space-between;

        h2 {
            font-size: 16px;
        }

        p {
            font-size: 13px;
        }

        button {
            font-size: 10px;
            padding: 10px 20px;
        }
    }

    @media ${device.xs} {
        width: 250px;
        height: 260px;
        padding: 50px 20px;
        justify-content: space-between;

        h2 {
            font-size: 14px;
        }

        p {
            font-size: 11px;
        }

        button {
            font-size: 10px;
            padding: 10px 20px;
        }
    }
`

const WhatsAppButton = styled.button`
    letter-spacing: 1.4px;
    border: solid 1px transparent;
    cursor: pointer;
    padding: 15px 20px;
    background-color: #4baf51;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 50px;
    transition: all .2s ease;
    margin-top: 30px;

    &:hover {
        box-shadow: 0px 0px 20px 0px  rgba(255, 255, 255, 0.2);
        background-color: #54c35c;
        border-color: white;
    }
`

export const Services = () => {

    const history: any = useHistory();

    const { handleLogEvent } = useLogEvent();

    const {
        handleClickGoHome,
    } = useAnimations()

    return (

        <Container>

            <Target id="services" />

            <ContentContainer>

                <TitleContainer className="utils__fade-in-fast-left">

                    <h1>Servicios</h1>

                    <WhatsAppButton
                        className="utils__fade-in-center"
                        onClick={() => {

                            window.open(globalTexts.whatsapp, '_blank');

                            handleLogEvent('click_boton_whatsapp_servicios');

                        }}>Info</WhatsAppButton>

                </TitleContainer>

                <InfoContainer>

                    <Card
                        className="utils__fade-in-fast-top"
                        onClick={async () => {

                            history.push('/flex');

                            setTimeout(() => {
                                handleClickGoHome()
                            }, 1000);

                            handleLogEvent('click_boton_servicio_paqueteria_flex');

                        }}
                    >

                        <h2>Paquetería Flex</h2>

                        <p>Envíos a CABA y GBA en el dia para Mercado Libre, Emprendimientos, Tiendas Online o Físicas.</p>

                        <button>
                            Ver más
                        </button>

                    </Card>

                    <Card
                        className="utils__fade-in-fast-top"
                        onClick={() => {

                            history.push('/epack');

                            setTimeout(() => {
                                handleClickGoHome()
                            }, 1000);

                            handleLogEvent('click_boton_servicio_epack_nacional');

                        }}
                    >

                        <h2>E-pack Nacional</h2>

                        <p>Nos encargamos de todo el proceso de empaquetado y gestión. Trabajamos asociados a Correo Argentino, Andreani, OCA, etc.</p>

                        <button>
                            Ver más
                        </button>


                    </Card>

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
