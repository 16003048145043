import { ContactForm } from "../components/ContactForm"
import { Header } from "../components/Header"
import { Introduction } from "../components/Introduction"
import { Rights } from "../components/Rights"
import { WsFloatButton } from "../components/WsFloatButton"
import { JobsList } from "../components/JobsList"
import { Coverage } from "../components/Coverage"
import { useEffect, useState } from "react"
import { scrollFadeInCenter, scrollFadeInFastCenter, scrollFadeInFastLeft, scrollFadeInFastRight, scrollFadeInFastTop, scrollFadeInLeft, scrollFadeInRight, scrollFadeInTop } from "../effects/Observers";
import { useLocation } from "react-router-dom"
import { Preloader } from "../components/Preloader"
import { flexServicesTexts } from "../constants/ServiceConstants"


export const JobsScreen = () => {

    const [showPreloader, setShowPreloader] = useState(true);

    const location = useLocation();

    const preloader = () => {

        setTimeout(() => {
            setShowPreloader(false);
        }, 2900);

    };

    useEffect(() => {

        preloader()

        scrollFadeInTop();
        scrollFadeInFastTop();

        scrollFadeInCenter();
        scrollFadeInFastCenter();

        scrollFadeInRight();
        scrollFadeInFastRight();

        scrollFadeInLeft();
        scrollFadeInFastLeft();

    }, [location.pathname])

    return (

        <div className='home__main-content'>

            {showPreloader && <Preloader />}

            <WsFloatButton />

            <Header />

            {/*Trabajos */}

            <JobsList />

            {/* Contacto */}

            <ContactForm />

            {/* Derechos */}

            <Rights />

        </div>

    )
}
