import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/firebase-config";

export const useLogEvent = () => {

    const handleLogEvent = (logName: string, params?: object) => {

        logEvent(analytics, logName, params ? params : {});

    }

    return {
        handleLogEvent
    };

}