import { CSSProperties, useState } from "react"
import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { FormProps } from "../interfaces/ContactInterfaces"
import { showMixinToast } from "../utils/Alerts"
import axios from 'axios'
import { endpoints } from "../helpers/FirebaseHelper"


const Container = styled.div`
    width: 100%;
    padding: 100px 0px 100px 0px;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #f3f3f3;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    max-width: 428px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

`

const TitleDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 100px;

    h1 {
        font-size: 34px;
        color: #151515;
        text-align: center;
        line-height: 50px;
    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        top: 60px;
        right: 0;
        margin: auto;
        width: 50px;
        height: 3px;
        border-radius: 50px;
        background-color: #5fda67;
    }

    
    @media ${device.md} {

        h1 {
            font-size: 32px;
        }

        h1::before {
            top: 65px;
        }

    }

    @media ${device.sm} {
        margin-bottom: 80px;

        h1 {
            font-size: 26px;
        }

        h1::before {
            top: 55px;
        }
    }

    @media ${device.xs} {

        h1 {
            font-size: 20px;
        }

        h1::before {
            top: 50px;
            width: 40px;
        }
    }


`

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
    padding: 50px 90px;
    border: 1px solid #eee;
    border-radius: 15px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

    & > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        & > hr {
            width: 30px;
            border: none;
        }

        @media ${device.md} {
            flex-direction: column;
            & > hr {
                height: 20px;
            }
        }

        & > h2 {
            margin-bottom: 20px;
        }

        & > p {
            margin-bottom: 10px;
        }

        @media ${device.sm} {
            & > h2 {
                font-size: 18px;
                text-align: center;

            }

            & > p {
                font-size: 14px;
                text-align: center;
            }
        }
        
    }

    @media ${device.md} {
        padding: 50px 50px;
    }

    @media ${device.sm} {
        width: 280px;
        padding: 40px 30px;
    }

    @media ${device.xs} {
        width: 200px;
    }
`

const InputDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 404px;
    align-self: start;

    & > div {
        width: 45% !important;
    }

    @media ${device.sm} {

        flex-direction: column;
        max-width: 280px;

        & > div {
            width: 100% !important;
        }

    }

    @media ${device.xs} {
        max-width: 280px;
    }

`

const InputContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    position: relative;
`

const Input = styled.input<{ incomplete: string }>`
    width: 100%;
    max-width: 404px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    border: 1px solid #b7babc;
    border-radius: 8px;
    box-shadow: none;
    height: 42px !important;
    padding-left: 20px;

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
        max-width: 256px;
    }

    @media ${device.xs} {
        max-width: 176px;
    }

    ${({ incomplete }) => incomplete === 'true' && css`
        border-color: #ff7071;
        background-color: #fff0f0;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `}

    &:focus {
        outline: 0 none;
        border-color: #4baf51;
    }

    label {
        color: #2c3357;
        font-size: 13px;
        font-weight: 400;
        left: 5px;
        position: absolute;
        top: -25px;
    }
`

const Label = styled.label`
    color: #333;
    font-size: 13px;
    font-weight: 400;
    left: 5px;
    position: absolute;
    top: -25px;
`

const TextArea = styled.textarea<{ incomplete: string }>`
    width: 100%;
    min-height: 100px;
    max-width: 394px;
    padding: 15px 12px 50px 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 16px;
    transition: all 0.5s ease-in-out;
    margin-bottom: 10px;
    border: 1px solid #b7babc;
    border-radius: 8px;
    box-shadow: none;
    height: 46px;
    padding-left: 20px;

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
        max-width: 246px;
    }

    @media ${device.xs} {
        max-width: 166px;
    }


    ${({ incomplete }) => incomplete === 'true' && css`
        border-color: #ff7071;
        background-color: #fff0f0;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `}

    &:focus {
        outline: 0 none;
        border-color: #4baf51;
    }
`

const SendButton = styled.button`
    position: relative;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: #4baf51;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 15px 30px;
    border-radius: 50px;
    margin-top: 20px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(255, 255, 255, 0.1);
    }

    &:hover {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
        background-color: #54c35c;

        &::before {
            animation: fillButton 0.3s ease-in-out forwards;
        }
    }

    @media ${device.sm} {
        font-size: 12px;
        padding: 15px 25px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }

`


export const ContactForm = () => {

    const { handleLogEvent } = useLogEvent();

    const [form, setForm] = useState<FormProps>({
        name: '',
        email: '',
        phone: '',
        message: '',
    })


    const [fieldIncomplete, setFieldIncomplete] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
    })

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            ...fieldIncomplete,
            name: form.name.trim() === '',
            email: form.email.trim() === '' || !form.email.trim().includes('@'),
            phone: form.phone.trim() === '',
            message: form.message.trim() === '',
        });

        if (form.name.trim() === '') {
            existError = true;
        }
        if (form.email.trim() === '') {
            existError = true;
        }
        if (form.phone.trim() === '') {
            existError = true;
        }
        if (form.message.trim() === '') {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async (e: any) => {

        e.preventDefault();

        if (!formValidations()) {

            setForm({
                name: '',
                email: '',
                phone: '',
                message: '',
            })

            setFieldIncomplete({
                name: false,
                email: false,
                phone: false,
                message: false,
            })

            await axios.post(
                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                from: form.email,
                to: process.env.REACT_APP_SEND_EMAIL_TO,
                name: form.name,
                phone: form.phone,
                text: form.message
            }).then(() => {

                handleLogEvent('click_boton_formulario_contacto');

            }).catch((e) => {
                console.log(e);

            }).finally(() => {

                showMixinToast('Mensaje enviado con exito!', 'success');

            })

        }

    }

    const styleCompleted: CSSProperties = {
        outline: '0 none',
        borderColor: '#4baf51',
    }

    return (

        <Container>

            <Target id="social" />

            <ContentContainer>

                <TitleDiv className="utils__fade-in-fast-center">

                    <h1>Contacto</h1>

                </TitleDiv>

                <FormContainer >

                    <div>

                        <h2 className="utils__fade-in-fast-right">¿Necesitas ayuda?</h2>

                        <p className="utils__fade-in-fast-left">¡Dejanos tu consulta y te estaremos contactando!</p>

                        <InputDiv className="utils__fade-in-fast-top">

                            <InputContainer>

                                <Label>Nombre</Label>

                                <Input
                                    type={"text"}
                                    placeholder={'Nombre'}
                                    value={form.name}
                                    incomplete={fieldIncomplete.name.toString()}
                                    onChange={(e: any) => {

                                        setFieldIncomplete({ ...fieldIncomplete, name: false })
                                        setForm({
                                            ...form,
                                            name: e.target.value
                                        })
                                    }}
                                    style={form.name.trim() !== '' ? { ...styleCompleted } : {}}
                                ></Input>


                            </InputContainer>

                            <InputContainer>

                                <Label>Celular</Label>

                                <Input
                                    type={"text"}
                                    placeholder={'Celular'}
                                    value={form.phone}
                                    incomplete={fieldIncomplete.phone.toString()}
                                    onChange={(e: any) => {
                                        setFieldIncomplete({ ...fieldIncomplete, phone: false })
                                        setForm({
                                            ...form,
                                            phone: e.target.value
                                        })
                                    }}
                                    style={form.phone.trim() !== '' ? { ...styleCompleted } : {}}
                                />

                            </InputContainer>

                        </InputDiv>

                        <InputContainer className="utils__fade-in-fast-top">

                            <Label>Email</Label>

                            <Input
                                type={"text"}
                                placeholder={'Email'}
                                value={form.email}
                                incomplete={fieldIncomplete.email.toString()}
                                onChange={(e: any) => {
                                    setFieldIncomplete({ ...fieldIncomplete, email: false })
                                    setForm({
                                        ...form,
                                        email: e.target.value
                                    })
                                }}
                                style={form.email.trim() !== '' ? { ...styleCompleted } : {}}
                            />

                        </InputContainer>

                        <InputContainer className="utils__fade-in-fast-top">

                            <Label>Mensaje</Label>

                            <TextArea
                                placeholder={'Mensaje'}
                                value={form.message}
                                incomplete={fieldIncomplete.message.toString()}
                                onChange={(e: any) => {
                                    setFieldIncomplete({ ...fieldIncomplete, message: false })
                                    setForm({
                                        ...form,
                                        message: e.target.value
                                    })
                                }}
                                style={form.message.trim() !== '' ? { ...styleCompleted } : {}}
                            />

                        </InputContainer>



                    </div>

                    <SendButton
                        className="utils__fade-in-fast-center"
                        onClick={(e) => handleFormConfirm(e)}
                    >
                        Enviar mensaje
                    </SendButton>

                </FormContainer>

            </ContentContainer>


        </Container>

    )
}
