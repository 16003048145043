import { CSSProperties, useEffect, useState } from 'react';
import { css, styled } from 'styled-components';
import { device } from '../styles/stylesConfig';

const LogoContainer = styled.div`


    @keyframes hide {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes typewriter {
        0% {
            width: 0px;
        }
    
        100% {
            @media ${device.xxl} {
                width: 500px;
            }
            @media ${device.md} {
                width: 350px;
            }
            @media ${device.sm} {
                width: 285px;
            }
            @media ${device.xs} {
                width: 285px;
            }
        }
    }

    @keyframes blink {
        0% {
        border-right-color: rgba(255,255,255,.75);
        }

        100% {
        border-right-color: transparent;
        }
    }

    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: #111111;
    animation: hide 2s ease 1s;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const TitleContainer = styled.div<{ visibility: string }>`
    display: flex;
    position: relative;
    overflow: hidden;
    border-right: 3px solid;
    width: 350px;
    height: fit-content;
    animation: typewriter 1s steps(20) normal 0.5s, blink 0.5s steps(20) infinite;
    align-self: center;
    margin-left: 30px;

    @media ${device.md} {
        width: 250px;
        margin-left: 10px;
    }
    @media ${device.sm} {
        width: 210px;
    }
    @media ${device.xs} {
        width: 210px;
    }

    ${({ visibility }) => visibility === 'false' && css`
        visibility: hidden;

    `}
    
`

const Title = styled.h1`
    font-family: 'Belleza', 'Montserrat', sans-serif;
    color: white;
    font-size: 40px;
    white-space: nowrap;
    letter-spacing: 5px;

    @media ${device.md} {
        font-size: 26px;
    }
    @media ${device.sm} {
        font-size: 20px;
    }
    @media ${device.xs} {
        font-size: 20px;
    }

`

const Logo = styled.div`
    width: 30vh;
    height: 30vh;
    background: url(${require('../images/logo1.webp')}) center no-repeat;
    background-size: contain;
    z-index: 0;

    @media ${device.md} {
        width: 20vh;
        height: 20vh;
    }
    @media ${device.sm} {
        width: 20vh;
        height: 20vh;
    }
    @media ${device.xs} {
        width: 20vh;
        height: 20vh;
    }
`

interface Props {
    styles?: CSSProperties;
}


export const Preloader = ({ styles }: Props) => {

    const [showTitle, setShowTitle] = useState(false);

    const handleClick = () => {

        setShowTitle(false);

        setTimeout(() => {
            setShowTitle(true);
        }, 550);

    };

    useEffect(() => {

        handleClick()

    }, [])

    return (

        <LogoContainer style={{ ...styles }}>

            <ContentContainer>

                <Logo />

                <TitleContainer visibility={showTitle.toString()}>

                    <Title>Nidex Logistica</Title>

                </TitleContainer>

            </ContentContainer>

        </LogoContainer>

    )
}
