import { About } from "../components/About"
import { ContactForm } from "../components/ContactForm"
import { Header } from "../components/Header"
import { Introduction } from "../components/Introduction"
import { Rights } from "../components/Rights"
import { Services } from "../components/Services"
import { Integrations } from "../components/Integrations"
import { Prices } from "../components/Prices"
import { Reviews } from "../components/Reviews"
import { WsFloatButton } from "../components/WsFloatButton"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { scrollFadeInCenter, scrollFadeInFastCenter, scrollFadeInFastLeft, scrollFadeInFastRight, scrollFadeInFastTop, scrollFadeInLeft, scrollFadeInRight, scrollFadeInTop } from "../effects/Observers";
import { Preloader } from "../components/Preloader"

export const HomeScreen = () => {

    const [showPreloader, setShowPreloader] = useState(true);

    const location = useLocation();

    const preloader = () => {

        setTimeout(() => {
            setShowPreloader(false);
        }, 2900);

    };

    useEffect(() => {

        preloader()

        scrollFadeInTop();
        scrollFadeInFastTop();

        scrollFadeInCenter();
        scrollFadeInFastCenter();

        scrollFadeInRight();
        scrollFadeInFastRight();

        scrollFadeInLeft();
        scrollFadeInFastLeft();

    }, [location.pathname])


    return (

        <div className='home__main-content'>

            {showPreloader && <Preloader />}

            <WsFloatButton />

            <Header />

            {/* Banner */}

            <Introduction />

            {/* Porque elegirnos */}

            <About />

            {/* Servicios */}

            <Services />

            {/* Integraciones */}

            <Integrations />

            {/* Precios */}

            <Prices />

            {/* Resenas y calificaciones */}

            <Reviews />

            {/* Contacto */}

            <ContactForm />

            {/* Derechos */}

            <Rights />

        </div>

    )
}
