import styled from "styled-components"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 50px 0px 120px 0px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 70px;

    h1 {
        font-size: 34px;
        color: #fff;

        @media ${device.md} {
            font-size: 28px;
        }

        @media ${device.sm} {
            font-size: 24px;
        }

        @media ${device.xs} {
            font-size: 18px;
        }

    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        top: 60px;
        right: 0;
        margin: auto;
        width: 80px;
        height: 3px;
        border-radius: 50px;
        background-color: #5fda67;

        @media ${device.md} {
            top: 55px;
            width: 70px;
        }

        @media ${device.sm} {
            top: 50px;
        }

        @media ${device.xs} {
            top: 40px;
            width: 60px;
        }
    }

    p {
        color: #eee;
        margin-top: 50px;
        width: 45%;
        text-align: center;
        line-height: 30px;

        @media ${device.lg} {
            width: 65%;
        }

        @media ${device.md} {
            width: 85%;
            font-size: 14px;
        }

        @media ${device.sm} {
            font-size: 12px;
            line-height: 24px;
            width: 90%;
        }


    }
    
    @media ${device.sm} {
        margin-bottom: 50px;
    }

`


const InfoContainer = styled.div`
    width: fit-content;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-area: center;
    gap: 50px;

    @media ${device.lg} {
        gap: 30px;
    }

    @media ${device.md} {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    @media ${device.sm} {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        row-gap: 30px;
    }

`

const Card = styled.div`
    width: 250px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    padding: 30px;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    box-shadow: 4px 4px 12px #0000001f;
    background-color: #f8f9fa;

    h2 {
        font-size: 20px;
        color: #4e83f1;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        color: #666;
        text-align: center;
    }

    @media ${device.xl} {
        width: 220px;
        padding: 20px;

        h2 {
            font-size: 18px;
        }

    }

    @media ${device.md} {
        width: 220px;
        padding: 20px;

        h2 {
            font-size: 18px;
        }

    }

    // >>>>>>>> ORDEN DEL GRID >>>>>>>>

    &:nth-child(4) { 
        grid-row: 2;
        margin-left: 181px;
        grid-column-start: 1;
        grid-column-end: 3;

        @media ${device.xl} {
            margin-left: 156px;
        }

        @media ${device.lg} {
            margin-left: 146px;
        }

        @media ${device.md} {
            grid-row: 2;
            margin-left: 0px;
            grid-column-start: 2;
            grid-column-end: 3;
        }

        @media ${device.sm} {
            grid-row: unset;
            margin-left: unset;
            grid-column-start: unset;
            grid-column-end: unset;
        }

    } 

    &:nth-child(5) { 
        grid-row: 2;
        margin-left: 181px;
        grid-column-start: 2;
        grid-column-end: 4;

        @media ${device.xl} {
            margin-left: 156px;
        }

        @media ${device.lg} {
            margin-left: 146px;
        }
        
        @media ${device.md} {
            grid-row: 3;
            margin-left: 146px;
            grid-column-start: 1;
            grid-column-end: 3;
        }

        @media ${device.sm} {
            grid-row: unset;
            margin-left: unset;
            grid-column-start: unset;
            grid-column-end: unset;
        }
    } 

    // <<<<<<<< ORDEN DEL GRID <<<<<<<<
`

const Image = styled.img`
    width: 120px;
    height: auto;
    margin-bottom: 50px;

    @media ${device.xl} {
        width: 110px;
    }
`

export const About = () => {

    return (

        <Container>

            <Target id="about" />

            <ContentContainer>

                <TitleContainer className="utils__fade-in-top-fast">

                    <h1 className="utils__fade-in-fast-top">¿Por qué elegirnos?</h1>

                    <p className="utils__fade-in-fast-top">Nidex Logistica se adapta a las necesidades de tu negocio brindando múltiples funcionalidades para que puedas gestionas el 360 de tu logística desde un solo lugar sin importar la escala de tu operación.</p>

                </TitleContainer>

                <InfoContainer>

                    <Card className="utils__fade-in-fast-center">

                        <Image
                            src={require('../images/repartidor.webp')}
                            alt=""
                        />

                        <h2>Envíos Full</h2>

                        <p>Ofrecele a tus clientes entregas en el dia de forma segura y rapida.</p>

                    </Card>

                    <Card className="utils__fade-in-fast-center">

                        <Image
                            src={require('../images/packing.webp')}
                            alt=""
                        />

                        <h2>Fullfilment</h2>

                        <p>Almacenamiento, preparación de pedidos y despachos.</p>

                    </Card>

                    <Card className="utils__fade-in-fast-center">

                        <Image
                            src={require('../images/polucion.webp')}
                            alt=""
                        />

                        <h2>Flota Propia</h2>

                        <p>Contamos con una amplia variedad de vehiculos especializados en entregas de paquetes y fletes.</p>

                    </Card>

                    <Card className="utils__fade-in-fast-center">

                        <Image
                            src={require('../images/camion1.webp')}
                            alt=""
                        />

                        <h2>Paquetería nacional</h2>

                        <p>Gestionamos tus envíos para todo el país a través de empresas como Andreani, Correo Argentino, OCA, etc.</p>

                    </Card>

                    <Card className="utils__fade-in-fast-center">

                        <Image
                            src={require('../images/apoyo.webp')}
                            alt=""
                        />

                        <h2>Atención al cliente</h2>

                        <p>WhatsApp 24hs, podes comunicarte con nosotros a través de mensaje o llamada.</p>

                    </Card>

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
