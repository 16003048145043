import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { useHistory } from "react-router-dom"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 100px 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 100px;

    h1 {
        font-size: 42px;
        color: #fff;
        letter-spacing: 5px;
    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        top: 70px;
        right: 0;
        margin: auto;
        width: 50px;
        height: 3px;
        border-radius: 50px;
        background-color: #5fda67;
    }

        
    @media ${device.md} {

        h1 {
            font-size: 32px;
        }

        h1::before {
            top: 65px;
        }

    }

    @media ${device.sm} {
        margin-bottom: 80px;

        h1 {
            font-size: 26px;
        }

        h1::before {
            top: 55px;
        }
    }

    @media ${device.xs} {

        h1 {
            font-size: 20px;
        }

        h1::before {
            top: 50px;
            width: 40px;
        }
    }

    
`

const InfoContainer = styled.div`
    width: fit-content;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-area: center;
    gap: 70px;

    @media ${device.lg} {
        gap: 50px;
    }

    @media ${device.md} {
        gap: 30px;
    }

    @media ${device.sm} {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        row-gap: 30px;
    }
`

const Card = styled.button`
    width: 360px;
    height: 600px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    z-index: 2;
    position: relative;
    padding: 0 30px;
    border-radius: 20px;
    box-shadow: 4px 4px 12px #0000001f;
    cursor: pointer;
    transition: all .5s ease;

    &:hover{

        button {
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
            background-color: #54c35c;
            border: 1px solid white;
        }
    }

    @media ${device.lg} {
        height: 480px;
        width: 420px;
    }

    @media ${device.md} {
        height: 440px;
        width: 260px;
    }

    @media ${device.sm} {
        height: 320px;
        width: 340px;
    }

    @media ${device.xs} {
        height: 320px;
        width: 290px;
    }
`

const CardImageDiv = styled.div`
    width: 100%;
    height: 100%;
    background: url(${require('../images/caja2.webp')}) center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    padding: 30px;
    border-radius: 18px 18px 0 0;

    &::before {
        content: "";
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        top: 0;
        border-radius: 18px 18px 0 0;
        background-color: rgba(0, 0, 0, 0.5);
    }
`

const CardDataDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    z-index: 2;
    position: relative;
    padding: 30px;
    border-radius: 0px 0px 20px 20px;

`

const CardTitle = styled.h2`
    font-size: 32px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 50px;
    margin-top: 30px;
    z-index: 1;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;

    @media ${device.lg} {
        font-size: 30px;
    }

    @media ${device.md} {
        font-size: 22px;
    }

    @media ${device.sm} {
        font-size: 18px;
        margin-top: 10px;
    }

    @media ${device.xs} {
        font-size: 16px;
        margin-top: 10px;
    }
`

const CardOldPrice = styled.p`
    height: fit-content;
    text-decoration: line-through;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: 400;
    color: #666;
    text-align: center;
    z-index: 1;
    position: absolute;
    margin: auto;
    padding: 8px 10px;
    border-radius: 4px;
    top: -45px;
    left: 0px;
    right: 35%;
    width: fit-content;

    @media ${device.lg} {
        top: -35px;
        font-size: 18px;
    }

    @media ${device.md} {
        font-size: 16px;
        left: -10px;
        top: -30px;
    }

    @media ${device.sm} {
        left: 0px;
        top: -30px;
    }

    @media ${device.xs} {
        top: -35px;
    }
`

const CardOffer = styled.p`
    height: fit-content;
    width: fit-content;
    font-size: 24px;
    font-weight: 700;
    color: #111111;
    background-color: #FF7A00;
    text-align: center;
    z-index: 1;
    position: absolute;
    margin: auto;
    padding: 5px 10px;
    border-radius: 4px;
    top: -45px;
    left: 40%;
    right: 0px;

    @media ${device.lg} {
        top: -35px;
        font-size: 18px;
    }

    @media ${device.md} {
        font-size: 16px;
        right: -10px;
        top: -30px;
    }

    @media ${device.sm} {
        right: 10px;
        top: -30px;
    }

    @media ${device.xs} {
        top: -35px;
    }
`

const CardNewPrice = styled.div`
    font-size: 50px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: #111111;
    text-align: center;
    z-index: 1;
    width: 80%;
    margin-top: 40px;
    position: relative;

    span {
        font-size: 18px;
        font-weight: normal;
        color: #333;
        display: block;
    }

    @media ${device.lg} {
        font-size: 40px;
        margin-top: 20px;
    }

    @media ${device.md} {
        font-size: 36px;
        
        span {
            font-size: 14px;
        }
    }

    @media ${device.sm} {
        font-size: 24px;
        margin-top: 15px;
        span {
            font-size: 12px;
        }
    }

`

const CardButton = styled.div`
    font-size: 14px;
    color: #fff;
    z-index: 1;
    text-transform: uppercase;
    padding: 15px 25px;
    border-radius: 50px;
    border: 1px solid transparent;
    backdrop-filter: blur(100px);
    background-color: #4baf51;
    font-weight: bold;
    transition: all .2s ease;
    position: absolute;
    margin: auto;
    bottom: 40px;
    cursor: pointer;

    @media ${device.lg} {
        font-size: 12px;
    }

    @media ${device.md} {
        font-size: 12px;
        padding: 10px 20px;
    }

    @media ${device.sm} {
        font-size: 12px;
        padding: 10px 20px;
        bottom: 20px;
    }

    @media ${device.xs} {
        font-size: 10px;
        padding: 10px 20px;
    }
`

export const Prices = () => {

    const history: any = useHistory()

    const { handleLogEvent } = useLogEvent();

    return (

        <Container>

            <Target id="prices" />

            <ContentContainer>

                <TitleContainer className="utils__fade-in-fast-center">

                    <h1>Precios</h1>

                </TitleContainer>

                <InfoContainer>

                    <Card
                        onClick={() => {

                            history.push('/flex');

                            handleLogEvent('click_boton_mas_info_precio_flex');
                        }}
                    >

                        <CardImageDiv>

                            <CardTitle className="utils__fade-in-fast-right">Envíos<br />Flex</CardTitle>

                            <CardButton className="utils__fade-in-fast-top">Mas Información</CardButton>

                        </CardImageDiv>

                        {/* <CardDataDiv>

                            <CardNewPrice className="utils__fade-in-fast-right">

                                $2.987<span>por paquete</span>

                                <CardOffer>50%</CardOffer>

                                <CardOldPrice>$5.994</CardOldPrice>

                            </CardNewPrice>

                            <CardButton className="utils__fade-in-fast-top">Mas Información</CardButton>

                        </CardDataDiv> */}

                    </Card>

                    <Card
                        onClick={() => {

                            history.push('/epack');

                            handleLogEvent('click_boton_mas_info_precio_epack');
                        }}
                    >

                        <CardImageDiv
                            style={{
                                background: `url(${require('../images/caja3.webp')}) center no-repeat`,
                                backgroundSize: 'cover'
                            }}
                        >

                            <CardTitle className="utils__fade-in-fast-left">E-paquetería premium</CardTitle>

                            <CardButton className="utils__fade-in-fast-top">Mas Información</CardButton>

                        </CardImageDiv>

                        {/* <CardDataDiv>

                            <CardNewPrice className="utils__fade-in-fast-left">

                                $1.897<span>por producto</span>

                                <CardOffer>50%</CardOffer>

                                <CardOldPrice>$3.994</CardOldPrice>

                            </CardNewPrice>

                            <CardButton className="utils__fade-in-fast-top">Mas Información</CardButton>

                        </CardDataDiv> */}

                    </Card>

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
