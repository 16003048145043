import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { ServiceOperationProps } from "../interfaces/TextsInterfaces"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 100px 0px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 70px;

    h1 {
        font-size: 34px;
        color: #fff;

        @media ${device.md} {
            font-size: 28px;
        }

        @media ${device.sm} {
            font-size: 24px;
        }

        @media ${device.xs} {
            font-size: 18px;
        }

    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        top: 60px;
        right: 0;
        margin: auto;
        width: 80px;
        height: 3px;
        border-radius: 50px;
        background-color: #5fda67;

        @media ${device.md} {
            top: 55px;
            width: 70px;
        }

        @media ${device.sm} {
            top: 50px;
        }

        @media ${device.xs} {
            top: 40px;
            width: 60px;
        }
    }

    @media ${device.sm} {
        margin-bottom: 50px;
    }

`


const InfoContainer = styled.div`
    width: fit-content;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-area: center;
    gap: 50px;

    @media ${device.xl} {
        gap: 30px;
    }

    @media ${device.lg} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        row-gap: 30px;
    }

    @media ${device.sm} {
        gap: 0px;
        row-gap: 30px;
    }
`


const Card = styled.div`
    width: 370px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    padding: 30px;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    box-shadow: 4px 4px 12px #0000001f;
    background-color: #f8f9fa;
    overflow: hidden;

    h2 {
        font-size: 20px;
        color: #111111;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
    }

    p {
        font-size: 14px;
        color: #666;
        text-align: center;
    }

    span {
        color: rgba(0, 0, 0, 0.05);
        font-size: 400px;
        font-weight: bold;
        position: absolute;
        right: -30px;
        margin: auto;
        z-index: -1;
    }

    @media ${device.xl} {
        width: 340px;
        padding: 20px;

        h2 {
            font-size: 18px;
        }

    }

    @media ${device.lg} {
        width: 200px;
        height: 200px;
        padding: 30px;
    }

    @media ${device.md} {
        width: 400px;
        height: 200px;
        padding: 30px;

        h2 {
            font-size: 18px;
        }

    }

    @media ${device.sm} {
        width: 300px;
        height: 180px;
        padding: 20px;

        h2 {
            font-size: 18px;
        }

        span {
            font-size: 350px;
        }
    }

    @media ${device.xs} {
        width: 220px;
        height: 140px;
        padding: 20px;

        h2 {
            font-size: 16px;
        }

        p {
            font-size: 12px;
        }

        span {
            font-size: 260px;
        }
    }
`

interface Props {
    content: ServiceOperationProps;
}

export const ServiceOperation = ({ content }: Props) => {

    return (

        <Container>

            <Target id="serviceOperation" />

            <ContentContainer>

                <TitleContainer>

                    <h1 className="utils__fade-in-fast-center">{content.title}</h1>

                </TitleContainer>

                <InfoContainer>

                    {
                        content.cards.map((value) => (

                            <Card key={value.id} className="utils__fade-in-fast-right">

                                <h2>{value.title}</h2>

                                <p>{value.description}</p>

                                <span>{value.id + 1}</span>

                            </Card>

                        ))
                    }

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
