
export const useAnimations = () => {

    const handleClickGoHome = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoCatalog = () => {
        const element = document.getElementById('catalog');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoQuotator = () => {
        const element = document.getElementById('quotator');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoSocial = () => {
        const element = document.getElementById('social');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    const handleClickGoHomeRefresh = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
    }

    const handleClickGoServices = () => {
        const element = document.getElementById('services');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoAbout = () => {
        const element = document.getElementById('about');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoPrices = () => {
        const element = document.getElementById('prices');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoHomeInstant = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
    }

    const handleClickGoLastWorks = () => {
        const element = document.getElementById('works');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    return {
        handleClickGoHome,
        handleClickGoCatalog,
        handleClickGoQuotator,
        handleClickGoSocial,
        handleClickGoHomeRefresh,
        handleClickGoServices,
        handleClickGoAbout,
        handleClickGoPrices,
        handleClickGoHomeInstant,
        handleClickGoLastWorks
    }

}