import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { globalTexts } from "../constants/GlobalConstants"

const Container = styled.div`
    width: 100%;
    height: 120vh;
    background: url(${require('../images/caja1.webp')}) center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &::after {
        content: '';
        position: absolute;
        margin: auto;
        width: 150%;
        height: 200px;
        left: -50%;
        bottom: -200px;
        background-color: #111;
        box-shadow: 50px -75px 50px 0px #111;
    }
`

const InfoContainer = styled.div`
    text-transform: uppercase;
    z-index: 2;
    color: white;
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: start;
    justify-content: center;
    margin-bottom: 10vh;

    h1 {
        font-size: 70px;
    }

    p {
        font-size: 30px;
        font-weight: 200;
        text-transform: none;
        margin-top: 20px;
    }

    @media ${device.lg} {
        h1 {
            font-size: 60px;
        }

        p {
            font-size: 26px;
        }
    }

    @media ${device.md} {
        align-items: center;
        h1 {
            font-size: 50px;
            text-align: center;
        }

        p {
            font-size: 24px;
            text-align: center;
        }
    }

    @media ${device.sm} {
        margin-bottom: 20vh;
        h1 {
            font-size: 36px;
        }

        p {
            font-size: 20px;
        }
    }

    @media ${device.xs} {
        h1 {
            font-size: 28px;
        }

        p {
            font-size: 16px;
        }
    }

`


const ButtonKnowMore = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 40px;
    background-color: #4baf51;
    color: #111111;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 50px;
    cursor: pointer;
    transition: all .2s ease;
    margin-top: 50px;
    margin-left: 60px;

    &:hover {
        box-shadow: 0px 0px 20px 0px  rgba(255, 255, 255, 0.2);
        background-color: #54c35c;
    }

    @media ${device.lg} {
        margin-left: 20px;
        font-size: 16px;
    }

    @media ${device.md} {
        margin-left: 0px;
        align-self: center;
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
        padding: 20px 40px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }
`

export const Introduction = () => {

    const { handleLogEvent } = useLogEvent();

    return (

        <Container id="home">

            <InfoContainer>

                <h1 className="utils__fade-in-center">Logistica 360º</h1>

                <p className="utils__fade-in-right">Delegá los envios de tu negocio.<br />¡Vos vendes y nosotros nos encargamos del resto!</p>

                <ButtonKnowMore className="utils__fade-in-top" onClick={() => {

                    window.open(globalTexts.whatsapp, '_blank');

                    handleLogEvent('click_boton_pedir_cotizacion_banner_introduccion');
                }}
                >

                    Pedir cotizacion

                </ButtonKnowMore>

            </InfoContainer>

        </Container>

    )
}
