import { CSSProperties, useState } from "react"
import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { FormProps } from "../interfaces/ContactInterfaces"
import { useHistory, useLocation } from "react-router-dom"
import axios from "axios"
import { endpoints } from "../helpers/FirebaseHelper"
import { showMixinToast } from "../utils/Alerts"


const Container = styled.div`
    width: 100%;
    min-height: 88vh;
    padding-top: 12vh;
    background: #111;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 2;

    &::after {
        content: '';
        position: absolute;
        margin: auto;
        width: 150%;
        height: 200px;
        left: -50%;
        bottom: -200px;
        background-color: #111;
        box-shadow: 50px -75px 50px 0px #111;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    max-width: 428px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 120px 0 50px 0;

`

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
    padding: 50px 90px;
    border: 1px solid #eee;
    border-radius: 15px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

    & > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        & > hr {
            width: 30px;
            border: none;
        }

        @media ${device.md} {
            flex-direction: column;
            & > hr {
                height: 20px;
            }
        }

        & > h2 {
            margin-bottom: 20px;
        }

        & > p {
            margin-bottom: 10px;
        }

        @media ${device.sm} {
            & > h2 {
                font-size: 18px;
                text-align: center;

            }

            & > p {
                font-size: 14px;
                text-align: center;
            }
        }
        
    }

    @media ${device.md} {
        padding: 50px 50px;
    }

    @media ${device.sm} {
        width: 280px;
        padding: 40px 30px;
    }

    @media ${device.xs} {
        width: 200px;
    }
`

const InputDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 404px;
    align-self: start;

    & > div {
        width: 45% !important;
    }

    @media ${device.sm} {

        flex-direction: column;
        max-width: 280px;

        & > div {
            width: 100% !important;
        }

    }

    @media ${device.xs} {
        max-width: 280px;
    }

`

const InputContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    position: relative;
`

const Input = styled.input<{ incomplete: string }>`
    width: 100%;
    max-width: 404px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    border: 1px solid #b7babc;
    border-radius: 8px;
    box-shadow: none;
    height: 42px !important;
    padding-left: 20px;

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
        max-width: 256px;
    }

    @media ${device.xs} {
        max-width: 176px;
    }

    ${({ incomplete }) => incomplete === 'true' && css`
        border-color: #ff7071;
        background-color: #fff0f0;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `}

    &:focus {
        outline: 0 none;
        border-color: #4baf51;
    }

    label {
        color: #2c3357;
        font-size: 13px;
        font-weight: 400;
        left: 5px;
        position: absolute;
        top: -25px;
    }
`

const Label = styled.label`
    color: #333;
    font-size: 13px;
    font-weight: 400;
    left: 5px;
    position: absolute;
    top: -25px;
`

const SendButton = styled.button`
    position: relative;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: #4baf51;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 15px 30px;
    border-radius: 50px;
    margin-top: 30px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(255, 255, 255, 0.1);
    }

    &:hover {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
        background-color: #54c35c;

        &::before {
            animation: fillButton 0.3s ease-in-out forwards;
        }
    }

    @media ${device.sm} {
        font-size: 12px;
        padding: 15px 25px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }

`

const ComeBackButton = styled.button`
    position: absolute;
    left: 0px;
    top: -80px;
    border-radius: 2px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    border-radius: 50px;
    border: 1px solid transparent;
    padding: 10px 20px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(255, 255, 255, 0.1);
    }

    &:hover {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
        border: 1px solid white;

        &::before {
            animation: fillButton 0.3s ease-in-out forwards;
        }
    }



`

const Icon = styled.img`
    width: 10px;
    height: auto;
    margin-right: 10px;

`

export const ApplicationForm = () => {

    const { handleLogEvent } = useLogEvent();

    const location = useLocation();

    const [form, setForm] = useState<FormProps>({
        name: '',
        email: '',
        phone: '',
        message: '',
    })

    const history: any = useHistory();

    const [fieldIncomplete, setFieldIncomplete] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
    })

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            ...fieldIncomplete,
            name: form.name.trim() === '',
            email: form.email.trim() === '' || !form.email.trim().includes('@'),
            phone: form.phone.trim() === '',
            message: form.message.trim() === '',
        });

        if (form.name.trim() === '') {
            existError = true;
        }
        if (form.email.trim() === '') {
            existError = true;
        }
        if (form.phone.trim() === '') {
            existError = true;
        }
        if (form.message.trim() === '') {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async (e: any) => {

        e.preventDefault();

        if (!formValidations()) {

            setForm({
                name: '',
                email: '',
                phone: '',
                message: '',
            })

            setFieldIncomplete({
                name: false,
                email: false,
                phone: false,
                message: false,
            })

            const job = location.pathname === '/jobs/1' ? 'Repartidor/a' : 'Armador/a de pedidos/pickers'

            await axios.post(
                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                from: form.email,
                to: process.env.REACT_APP_SEND_EMAIL_TO,
                name: form.name,
                phone: form.phone,
                text: `\n\n- Empleo: '${job}' \n- Vehiculo a utilizar: '${form.message}'\n\n`
            }).then(() => {

                handleLogEvent('click_boton_formulario_solicitud_empleo_enviado');

            }).catch((e) => {
                console.log(e);

            }).finally(() => {

                showMixinToast('Solicitud enviada con exito!', 'success');

            })

        }

    }

    const styleCompleted: CSSProperties = {
        outline: '0 none',
        borderColor: '#4baf51',
    }

    return (

        <Container>

            <ContentContainer>

                <FormContainer>

                    <ComeBackButton
                        className="utils__fade-in-center"
                        onClick={() => {

                            history.push('/jobs');

                        }}
                    >
                        <Icon
                            src={require('../images/flecha.webp')}
                            alt="ubicacion"
                        /> Volver a empleos
                    </ComeBackButton>

                    <div>

                        <h2 className="utils__fade-in-right">Postulate ahora</h2>

                        <p className="utils__fade-in-left">¡Dejanos tus datos y te estaremos contactando!</p>

                        <InputContainer>

                            <Label className="utils__fade-in-center">Nombre y apellido</Label>

                            <Input
                                className="utils__fade-in-top"
                                type={"text"}
                                placeholder={'Nombre y apellido'}
                                value={form.name}
                                incomplete={fieldIncomplete.name.toString()}
                                onChange={(e: any) => {

                                    setFieldIncomplete({ ...fieldIncomplete, name: false })
                                    setForm({
                                        ...form,
                                        name: e.target.value
                                    })
                                }}
                                style={form.name.trim() !== '' ? { ...styleCompleted } : {}}
                            ></Input>


                        </InputContainer>

                        <InputDiv>

                            <InputContainer>

                                <Label className="utils__fade-in-center">Celular</Label>

                                <Input
                                    className="utils__fade-in-top"
                                    type={"text"}
                                    placeholder={'Celular'}
                                    value={form.phone}
                                    incomplete={fieldIncomplete.phone.toString()}
                                    onChange={(e: any) => {
                                        setFieldIncomplete({ ...fieldIncomplete, phone: false })
                                        setForm({
                                            ...form,
                                            phone: e.target.value
                                        })
                                    }}
                                    style={form.phone.trim() !== '' ? { ...styleCompleted } : {}}
                                />

                            </InputContainer>

                            <InputContainer>

                                <Label className="utils__fade-in-center">Vehiculo a utilizar</Label>

                                <Input
                                    className="utils__fade-in-top"
                                    type={"text"}
                                    placeholder={'Vehiculo a utilizar'}
                                    value={form.message}
                                    incomplete={fieldIncomplete.message.toString()}
                                    onChange={(e: any) => {

                                        setFieldIncomplete({ ...fieldIncomplete, message: false })
                                        setForm({
                                            ...form,
                                            message: e.target.value
                                        })
                                    }}
                                    style={form.message.trim() !== '' ? { ...styleCompleted } : {}}
                                ></Input>


                            </InputContainer>


                        </InputDiv>

                        <InputContainer>

                            <Label className="utils__fade-in-center">Email</Label>

                            <Input
                                className="utils__fade-in-top"
                                type={"text"}
                                placeholder={'Email'}
                                value={form.email}
                                incomplete={fieldIncomplete.email.toString()}
                                onChange={(e: any) => {
                                    setFieldIncomplete({ ...fieldIncomplete, email: false })
                                    setForm({
                                        ...form,
                                        email: e.target.value
                                    })
                                }}
                                style={form.email.trim() !== '' ? { ...styleCompleted } : {}}
                            />

                        </InputContainer>


                    </div>

                    <SendButton
                        className="utils__fade-in-center"
                        onClick={(e) => handleFormConfirm(e)}
                    >
                        Enviar solicitud
                    </SendButton>

                </FormContainer>

            </ContentContainer>


        </Container>

    )
}
