import styled from "styled-components"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 100px 0px 100px 0px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 100px;

    h1 {
        font-size: 36px;
        color: #151515;
        width: 35%;
        text-align: center;
        line-height: 50px;
    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -30px;
        right: 0;
        margin: auto;
        width: 80px;
        height: 3px;
        border-radius: 50px;
        background-color: #5fda67;
    }

    @media ${device.xl} {

        h1 {
            font-size: 32px;
        }

    }

    @media ${device.lg} {

        h1 {
            width: 50%;
        }

    }

    @media ${device.md} {

        h1 {
            font-size: 28px;
            width: 65%;
        }

    }

    @media ${device.sm} {

        h1 {
            font-size: 18px;
            line-height: 30px;
        }

        h1::before {
            top: 80px;
            width: 70px;
        }
    }

    @media ${device.xs} {

        h1 {
            width: 55%;
        }

        h1::before {
            top: 120px;
        }
    }
`


const InfoContainer = styled.div`
    width: fit-content;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    grid-area: center;
    gap: 120px;
    row-gap: 50px;


    @media ${device.lg} {
        gap: 100px;
        row-gap: 40px;
    }

    @media ${device.md} {
        grid-template-columns: repeat(3, 1fr);
        gap: 70px;
        row-gap: 30px;
    }

    @media ${device.sm} {
        gap: 50px;
        row-gap: 30px;
    }

    @media ${device.xs} {
        gap: 40px;
        row-gap: 30px;
    }
`

const Image = styled.img`
    width: 200px;
    height: auto;
    cursor: pointer;
    transition: all .5s ease;

    &:hover{
        transform: scale(1.1);
    }

    @media ${device.lg} {
        width: 120px;
    }

    @media ${device.sm} {
        width: 80px;
    }

    @media ${device.xs} {
        width: 60px;
    }
    
`

export const Integrations = () => {

    return (

        <Container>

            <Target id="integrations" />

            <ContentContainer>

                <TitleContainer className="utils__fade-in-fast-top">

                    <h1>Nuestros clientes utilizan estos medios de venta</h1>

                </TitleContainer>

                <InfoContainer>

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/mercadolibre.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/shopify.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/tiendanube.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/woocomerce.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/empretienda-logo.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/facebook-logo1.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/wordpress-logo.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/mercadolibre-flex.webp')}
                        alt=""
                    />


                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/tienda-fisica-logo.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/whatsap.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/facebook-marketplace.webp')}
                        alt=""
                    />

                    <Image
                        className="utils__fade-in-fast-center"
                        src={require('../images/instagram.webp')}
                        alt=""
                    />

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
