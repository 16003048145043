import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useHistory } from "react-router-dom"
import { useLogEvent } from "../hooks/useLogEvent"

const Container = styled.div`
    width: 100%;
    min-height: 88vh;
    padding-top: 12vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 50px 0px 120px 0px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 70px;

    h1 {
        font-size: 34px;
        color: #fff;

        @media ${device.md} {
            font-size: 28px;
        }

        @media ${device.sm} {
            font-size: 24px;
        }

        @media ${device.xs} {
            font-size: 18px;
        }

    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        top: 60px;
        right: 0;
        margin: auto;
        width: 80px;
        height: 3px;
        border-radius: 50px;
        background-color: #5fda67;

        @media ${device.md} {
            top: 55px;
            width: 70px;
        }

        @media ${device.sm} {
            top: 50px;
        }

        @media ${device.xs} {
            top: 40px;
            width: 60px;
        }
    }

    p {
        color: #eee;
        margin-top: 50px;
        width: 45%;
        text-align: center;
        line-height: 30px;

        @media ${device.lg} {
            width: 65%;
        }

        @media ${device.md} {
            width: 85%;
            font-size: 14px;
        }

        @media ${device.sm} {
            font-size: 12px;
            line-height: 24px;
            width: 90%;
        }


    }
    
    @media ${device.sm} {
        margin-bottom: 50px;
    }

`


const InfoContainer = styled.div`
    width: fit-content;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(1, 1fr);
    grid-area: center;
    row-gap: 50px;

    @media ${device.md} {
        row-gap: 30px;
    }

`

const Card = styled.button`
    width: 500px;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 2;
    position: relative;
    padding: 20px 30px;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    box-shadow: 4px 4px 12px #0000001f;
    background-color: #f8f9fa;
    transition: all .3s ease;

    &:hover {
        cursor: pointer;

        button {
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
            color: #151515;
            border: 1px solid #151515;
        }
    }

    @media ${device.sm} {
        width: 350px;
        padding: 20px;
    }

    @media ${device.xs} {
        width: 250px;
        height: 250px;
        flex-direction: column;
    }
    
`

const Image = styled.img`
    width: 120px;
    height: auto;

    @media ${device.sm} {
        width: 80px;
    }

    @media ${device.xs} {
        width: 60px;
    }
`

const DivContent = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        font-size: 18px;
        color: #4e83f1;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        color: #666;
        text-align: center;
    }


    @media ${device.sm} {


        h2 {
            font-size: 14px;
        }

        p {
            font-size: 12px;
        }

    }
`

const LocationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const Icon = styled.img`
    width: 20px;
    height: auto;
    margin-right: 10px;

    @media ${device.sm} {
        width: 16px;
    }
`


const CardButton = styled.div`
    width: fit-content;
    font-size: 14px;
    color: #666;
    z-index: 1;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 50px;
    border: 1px solid #666;
    backdrop-filter: blur(100px);
    cursor: pointer;
    transition: all .3s ease;
    align-self: center;
    margin-top: 20px;

    @media ${device.sm} {
        font-size: 12px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }
`

export const JobsList = () => {

    const history: any = useHistory();

    const { handleLogEvent } = useLogEvent();

    return (

        <Container>

            <Target id="home" />

            <ContentContainer>

                <TitleContainer>

                    <h1 className="utils__fade-in-top">Oportunidades de empleo</h1>

                </TitleContainer>

                <InfoContainer>

                    <Card
                        onClick={() => {

                            history.push('/jobs/1');

                            handleLogEvent('click_boton_postularme_en_empleos');
                        }}
                    >

                        <Image
                            className="utils__fade-in-right"
                            src={require('../images/entrega.webp')}
                            alt="moto"
                        />

                        <DivContent>

                            <h2 className="utils__fade-in-left">Repartidor/a</h2>

                            <LocationContainer className="utils__fade-in-top">

                                <Icon
                                    src={require('../images/ubicacion.webp')}
                                    alt="ubicacion"
                                />

                                <p>AMBA, Buenos Aires</p>

                            </LocationContainer>

                            <CardButton className="utils__fade-in-center">Postularme</CardButton>

                        </DivContent>

                    </Card>

                    <Card
                        onClick={() => {

                            history.push('/jobs/2');

                            handleLogEvent('click_boton_postularme_en_empleos');

                        }}
                    >

                        <Image
                            className="utils__fade-in-right"
                            src={require('../images/paquete.webp')}
                            alt="moto"
                        />

                        <DivContent>

                            <h2 className="utils__fade-in-left">Armador/a de<br />pedidos/pickers</h2>

                            <LocationContainer className="utils__fade-in-top">

                                <Icon
                                    src={require('../images/ubicacion.webp')}
                                    alt="ubicacion"
                                />

                                <p>Buenos Aires</p>

                            </LocationContainer>

                            <CardButton className="utils__fade-in-center">Postularme</CardButton>

                        </DivContent>

                    </Card>

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
