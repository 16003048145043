import { Header } from "../components/Header"
import { WsFloatButton } from "../components/WsFloatButton"
import { ApplicationForm } from "../components/ApplicationForm"
import { useEffect, useState } from "react";
import { scrollFadeInCenter, scrollFadeInFastCenter, scrollFadeInFastLeft, scrollFadeInFastRight, scrollFadeInFastTop, scrollFadeInLeft, scrollFadeInRight, scrollFadeInTop } from "../effects/Observers";
import { useLocation } from "react-router-dom";
import { Preloader } from "../components/Preloader";

export const JobScreen = () => {

    const [showPreloader, setShowPreloader] = useState(true);

    const location = useLocation();

    const preloader = () => {

        setTimeout(() => {
            setShowPreloader(false);
        }, 2900);

    };

    useEffect(() => {

        preloader()

        scrollFadeInTop();
        scrollFadeInFastTop();

        scrollFadeInCenter();
        scrollFadeInFastCenter();

        scrollFadeInRight();
        scrollFadeInFastRight();

        scrollFadeInLeft();
        scrollFadeInFastLeft();

    }, [location.pathname])

    return (

        <div className='home__main-content'>

            {showPreloader && <Preloader />}

            <WsFloatButton />

            <Header />

            {/* Contacto */}

            <ApplicationForm />

        </div>

    )
}
