import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { CardStack } from "./CardStack"
import { useLogEvent } from "../hooks/useLogEvent"
import { globalTexts } from "../constants/GlobalConstants"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 1100px;
    margin: 100px 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    @media ${device.lg} {
        width: 90%;
        flex-direction: column;
    }

`

const TitleContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-right: 30px;

    h1 {
        width: 100%;
        font-size: 42px;
        color: #151515;
        letter-spacing: 5px;
    }

    @media ${device.lg} {
        width: fit-content;
        margin-right: 0px;
        text-align: center;
        margin-bottom: 120px;

        h1 {
            font-size: 32px;
        }

        h1::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -30px;
            right: 0;
            margin: auto;
            width: 80px;
            height: 3px;
            border-radius: 50px;
            background-color: #5fda67;
        }

    }

    @media ${device.sm} {
        margin-bottom: 120px;

        h1 {
            font-size: 26px;
        }
    }

    @media ${device.xs} {

        h1 {
            font-size: 20px;
        }
    }
    
`

const InfoContainer = styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: 20px 0;
    position: relative;
`

const WhatsAppButton = styled.button`
    letter-spacing: 1.4px;
    border: solid 1px transparent;
    cursor: pointer;
    padding: 15px 20px;
    background-color: #4baf51;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 50px;
    transition: all .2s ease;
    margin-top: 30px;

    &:hover {
        box-shadow: 0px 0px 20px 0px  rgba(255, 255, 255, 0.2);
        background-color: #54c35c;
        border-color: white;
    }
`

export const Reviews = () => {

    const { handleLogEvent } = useLogEvent();

    const CARDS = [
        {
            id: 0,
            name: "Nahuel C.",
            designation: "Autoestetica MC",
            content: (
                <p>
                    “Me ayudaron un montón en la gestión de mis envíos de Mercado Libre.
                    Unos capos, <span>se encargaron de todo.</span> Gracias a ellos me pude desligar de los envíos.”
                </p>
            ),
        },
        {
            id: 1,
            name: "Matias B.",
            designation: "Iphone Muniz",
            content: (
                <p>
                    “La atención al cliente es excelente.
                    Los chicos son muy <span>responsables</span> al momento
                    de entregar <span>en tiempo y forma</span>. Lo recomiendo”
                </p>
            ),
        },
        {
            id: 2,
            name: "Nicolas E.",
            designation: "Champs Store",
            content: (
                <p>
                    “Excelente servicio y atención que brindan estos chicos.”
                </p>
            ),
        },
        {
            id: 2,
            name: "Franco G.",
            designation: "Hall Importador",
            content: (
                <p>
                    “Los chicos me ayudaron a crecer mi cuenta de Mercado Libre
                    gracias a la <span>rapidez</span> de sus envíos.”
                </p>
            ),
        },
    ];

    return (

        <Container>

            <Target id="reviews" />

            <ContentContainer style={{
                msScrollSnapY: ''
            }}>

                <TitleContainer className="utils__fade-in-fast-right">

                    <h1>Nuestras reseñas</h1>

                    <WhatsAppButton
                        className="utils__fade-in-center"
                        onClick={() => {

                            window.open(globalTexts.whatsapp, '_blank');

                            handleLogEvent('click_boton_whatsapp_reseñas');

                        }}>WhatsApp</WhatsAppButton>

                </TitleContainer>

                <InfoContainer className="utils__fade-in-fast-left">

                    <CardStack items={CARDS} />

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
