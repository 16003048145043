import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { ServiceCoverageProps } from "../interfaces/TextsInterfaces"

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 100px 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 100px;

    h1 {
        font-size: 42px;
        color: #fff;
        letter-spacing: 5px;
    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        top: 70px;
        right: 0;
        margin: auto;
        width: 50px;
        height: 3px;
        border-radius: 50px;
        background-color: #5fda67;
    }
    
    
    @media ${device.md} {

        h1 {
            font-size: 32px;
        }

        h1::before {
            top: 65px;
        }

    }

    @media ${device.sm} {
        margin-bottom: 80px;

        h1 {
            font-size: 26px;
        }

        h1::before {
            top: 55px;
        }
    }

    @media ${device.xs} {

        h1 {
            font-size: 20px;
        }

        h1::before {
            top: 50px;
            width: 40px;
        }
    }

`

const Card = styled.div`
    width: 60vh;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    z-index: 2;
    position: relative;
    padding: 30px;
    border: 1px solid #222;
    border-radius: 20px;
    box-shadow: 4px 4px 12px #0000001f;
    transition: all .5s ease;

    @media ${device.lg} {
        width: 45vh;
        height: 45vh;
    }

    @media ${device.sm} {
        width: 30vh;
        height: 30vh;
    }
`

interface Props {
    content: ServiceCoverageProps;
}

export const Coverage = ({ content }: Props) => {

    return (

        <Container>

            <Target id="coverage" />

            <ContentContainer>

                <TitleContainer>

                    <h1 className="utils__fade-in-fast-top">{content.title}</h1>

                </TitleContainer>

                <Card
                    className="utils__fade-in-fast-center"
                    style={{
                        background: `url(${content.image}) center no-repeat`,
                        backgroundSize: 'contain'
                    }}
                />

            </ContentContainer>

        </Container>

    )
}
