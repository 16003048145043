import { globalTexts } from "../constants/GlobalConstants";
import { useLogEvent } from "../hooks/useLogEvent";

export const WsFloatButton = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <a
            className='button__floatButton utils__fade-in-left'
            target="_blank"
            href={globalTexts.whatsapp}
            rel="noreferrer"
            onClick={() => {
                handleLogEvent('Click_boton_whatsapp_flotante');
            }}
        >

            <img
                src={require('../images/whatsapp-logo.webp')}
                alt='Logo WhatsApp'
            />

        </a>

    )
}
