import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";

const Card = styled(motion.div)`
    width: 400px;
    height: 240px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #eee;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    transform-origin: top center;
    cursor: pointer;

    @media ${device.sm} {
        width: 280px;
        height: 200px;
    }

    @media ${device.xs} {
        width: 200px;
        height: 200px;
        padding: 25px;
    }
`

const Paragraph = styled.h3`
    line-height: 24px;
    font-weight: 300;
    font-size: 16px;

    span {
        background-color: #D1FAE5;
        color: #047857;
        font-weight: bold;
        padding: 5px 0;
    }

    @media ${device.sm} {
        font-size: 14px;
    }

    @media ${device.xs} {
        font-size: 12px;
    }
`

const Author = styled.p`
    font-weight: 500;
    color: #484848;

    @media ${device.sm} {
        font-size: 14px;
    }

    @media ${device.xs} {
        font-size: 12px;
    }
`

const Company = styled.p`
    color: #696969;

    @media ${device.sm} {
        font-size: 14px;
    }

    @media ${device.xs} {
        font-size: 12px;
    }
`


let interval: any;

type Card = {
    id: number;
    name: string;
    designation: string;
    content: React.ReactNode;
};

export const CardStack = ({
    items,
    offset,
    scaleFactor,
}: {
    items: Card[];
    offset?: number;
    scaleFactor?: number;
}) => {
    const CARD_OFFSET = offset ?? 10;
    const SCALE_FACTOR = scaleFactor ?? 0.06;
    const [cards, setCards] = useState<Card[]>(items);

    useEffect(() => {

        interval = setInterval(() => {
            setCards((prevCards: Card[]) => {
                const newArray = [...prevCards];
                newArray.unshift(newArray.pop()!);
                return newArray;
            });
        }, 5000);

        return () => clearInterval(interval);

    }, [])

    const handleChangeReviewManual = () => {
        setCards((prevCards: Card[]) => {
            const newArray = [...prevCards];
            newArray.unshift(newArray.pop()!);
            return newArray;
        });
    }

    return (
        <>
            {cards.map((card, index) => {
                return (
                    <Card
                        onClick={() => handleChangeReviewManual()}
                        key={new Date().toISOString() + index}
                        animate={{
                            top: index * -CARD_OFFSET,
                            scale: 1 - index * SCALE_FACTOR,
                            zIndex: cards.length - index,
                        }}
                    >
                        <Paragraph>{card.content}</Paragraph>

                        <div style={{
                            width: '100%',
                        }}>

                            <Author>{card.name}</Author>

                            <Company>{card.designation}</Company>

                        </div>

                    </Card>
                );
            })}
        </>
    );
};
