import { styled } from 'styled-components';
import { device } from '../styles/stylesConfig';
import { useHistory } from 'react-router-dom';
import { useAnimations } from '../effects/Effects';
import { useLogEvent } from '../hooks/useLogEvent';

const LogoContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: all 0.75s;
    padding: 0;
    cursor: pointer;
`

const LogoImage = styled.img`
    width: 80px;
    height: auto;

    @media ${device.lg} {
        width: 60px;
        height: auto;
    }

    @media ${device.sm} {
        width: 50px;
        height: auto;
    }
`

export const AppLogoComponent = () => {

    const history: any = useHistory();

    const {
        handleClickGoHome
    } = useAnimations()

    const { handleLogEvent } = useLogEvent();

    return (

        <LogoContainer
            onClick={async () => {

                await history.push('/');

                await handleClickGoHome()

                handleLogEvent('click_logo');

            }}
        >

            <LogoImage
                src={require('../images/logo1.webp')}
                alt='logo'
            />

        </LogoContainer>

    )
}
