import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from "react-router-dom";
import { HomeScreen } from '../screens/HomeScreen';
import { ServiceScreen } from "../screens/ServiceScreen";
import { JobScreen } from "../screens/JobScreen";
import { JobsScreen } from "../screens/JobsScreen";

export const AppRouter = () => {

    console.warn = function () { };
    console.error = function () { };

    return (

        <Router>

            <div className='router-content'>

                <Switch>

                    <Route
                        exact
                        path='/'
                        component={HomeScreen}
                    />

                    <Route
                        exact
                        path='/:service(flex|epack)'
                        component={ServiceScreen}
                    />

                    <Route
                        exact
                        path='/jobs'
                        component={JobsScreen}
                    />

                    <Route
                        exact
                        path='/jobs/:id(1|2)'
                        component={JobScreen}
                    />

                    <Redirect to='/' />

                </Switch>

            </div>

        </Router>

    )
}
