import { useEffect, useState } from "react";
import { AppLogoComponent } from "./AppLogoComponent"
import { css, styled } from 'styled-components';
import { device } from "../styles/stylesConfig";
import { MenuButton } from "./MenuButton";
import { Menu } from "./Menu";
import { useAnimations } from "../effects/Effects";
import { useHistory, useLocation } from "react-router-dom";
import { useLogEvent } from "../hooks/useLogEvent";
import { globalTexts } from "../constants/GlobalConstants";

const Container = styled.div<{ show: string }>`
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 3;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    background-color: transparent;
    padding: 7.3px 0px;
    transition: all 0.75s ease-in;

    /* @media ${device.lg} {
        height: 150px;
        padding: 30px 0px;
    } */

    @media ${device.lg} {
        height: 70px;
        padding: 7.3px 0px;
    }

    ${({ show }) => show === 'true' && css`
        background: rgba(124, 124, 124, 0.5);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(2px);
    `}  

`

const MenuDiv = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    /* @media ${device.lg} {
        height: 100%;
        width: 90%;
        flex-direction: column;
    } */

    @media ${device.lg} {
        width: 80%;
        height: auto;
        flex-direction: row;
    }

`

const NavDiv = styled.div`
    width: 900px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-between;

    /* @media ${device.lg} {
        width: 100%;
    } */
    
    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    }

    & > button {
        color: white;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 1.4px;
        padding: 10px;
        border-top: solid 1px transparent;
        border-bottom: solid 1px transparent;
        cursor: pointer;
        
        &:hover {
            border-color: white;
        }

}
`

const WhatsAppButton = styled.button`
    letter-spacing: 1.4px !important;
    border: solid 1px transparent !important;
    cursor: pointer !important;
    padding: 15px 20px !important;
    background-color: #4baf51 !important;
    color: #fff !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    border-radius: 50px !important;
    transition: all .2s ease !important;

    &:hover {
        box-shadow: 0px 0px 20px 0px  rgba(255, 255, 255, 0.2) !important;
        background-color: #54c35c !important;
        border-color: white !important;
    }
`

export const Header = () => {

    const [show, setShow] = useState(false);

    const [showMenu, setShowMenu] = useState(false);

    const transitionNavBar = () => {

        if (window.scrollY > 50) {
            setShow(true);
        } else {
            setShow(false);
        }

    }

    useEffect(() => {

        transitionNavBar()

        window.addEventListener('scroll', transitionNavBar);

        return () => window.removeEventListener('scroll', transitionNavBar);

    }, []);

    const {
        handleClickGoHome,
        handleClickGoServices,
        handleClickGoPrices,
        handleClickGoSocial
    } = useAnimations()

    const history: any = useHistory();

    const location = useLocation();

    const { handleLogEvent } = useLogEvent();

    return (

        <Container show={show.toString()}>

            <MenuDiv>

                <AppLogoComponent />

                <NavDiv>

                    <button onClick={async () => {

                        if (location.pathname !== "/") {
                            await history.push('/');

                            setTimeout(() => {
                                handleClickGoHome()
                            }, 1000);
                        } else {
                            handleClickGoHome();
                        }

                        handleLogEvent('click_boton_inicio_menu');

                    }}>
                        Inicio
                    </button>

                    <button onClick={async () => {

                        if (location.pathname !== "/") {
                            await history.push('/');

                            setTimeout(() => {
                                handleClickGoServices()
                            }, 1000);
                        } else {
                            handleClickGoServices();
                        }

                        handleLogEvent('click_boton_servicios_menu');

                    }}>
                        Servicios
                    </button>

                    <button onClick={async () => {

                        if (location.pathname !== "/") {
                            await history.push('/');

                            setTimeout(() => {
                                handleClickGoPrices()
                            }, 1000);
                        } else {
                            handleClickGoPrices();
                        }

                        handleLogEvent('click_boton_precios_menu');

                    }}>
                        Precios
                    </button>

                    <button onClick={async () => {

                        if (location.pathname !== '/jobs') {

                            history.push('/jobs');

                            setTimeout(() => {
                                handleClickGoHome()
                            }, 1000);

                        } else {

                            handleClickGoHome()

                        }

                        handleLogEvent('click_boton_trabaja_con_nosotros_menu');


                    }}>
                        Trabajemos juntos
                    </button>

                    <button onClick={async () => {

                        handleClickGoSocial()

                        handleLogEvent('click_boton_contacto_menu');

                    }}>
                        Contacto
                    </button>

                    <WhatsAppButton onClick={async () => {

                        window.open(globalTexts.whatsapp, '_blank');

                        handleLogEvent('click_boton_whatsapp_menu');

                    }}>Ir a WhatsApp</WhatsAppButton>

                </NavDiv>

                <MenuButton showMenu={showMenu} setShowMenu={setShowMenu} />

            </MenuDiv>

            <Menu showMenu={showMenu} setShowMenu={setShowMenu} />

        </Container>

    )
}
