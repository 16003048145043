import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { ServiceAboutProps } from "../interfaces/TextsInterfaces"

const Container = styled.div`
    width: 100%;
    min-height: 85vh;
    padding-top: 12vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`
const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 50px 0px 120px 0px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 70px;

    h1 {
        font-size: 34px;
        color: #fff;

        @media ${device.md} {
            font-size: 28px;
        }

        @media ${device.sm} {
            font-size: 24px;
        }

        @media ${device.xs} {
            font-size: 18px;
        }

    }

    h1::before {
        content: "";
        position: absolute;
        left: 0;
        top: 60px;
        right: 0;
        margin: auto;
        width: 80px;
        height: 3px;
        border-radius: 50px;
        background-color: #5fda67;

        @media ${device.md} {
            top: 55px;
            width: 70px;
        }

        @media ${device.sm} {
            top: 50px;
        }

        @media ${device.xs} {
            top: 40px;
            width: 60px;
        }
    }

    p {
        color: #eee;
        margin-top: 50px;
        width: 45%;
        text-align: center;
        line-height: 30px;

        @media ${device.lg} {
            width: 65%;
        }

        @media ${device.md} {
            width: 85%;
            font-size: 14px;
        }

        @media ${device.sm} {
            font-size: 12px;
            line-height: 24px;
            width: 90%;
        }


    }
    
    @media ${device.sm} {
        margin-bottom: 50px;
    }

`


const InfoContainer = styled.div`
    width: fit-content;
    display: grid;
    align-items: start;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-area: center;
    gap: 120px;

    @media ${device.lg} {
        gap: 60px;
    }

    @media ${device.md} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`


const Card = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    border-radius: 20px;


    h2 {
        font-size: 20px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        color: #f8f9fa;
        text-align: center;
    }


    @media ${device.md} {
        width: 80%;

        h2 {
            font-size: 18px;
        }

    }
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: #222;
    border-radius: 1000px;
    border: 25px solid #4baf51;
    margin-bottom: 50px;
    
`

const Image = styled.img`
    width: 70px;
    height: auto;

`

interface Props {
    content: ServiceAboutProps;
}

export const ServiceAbout = ({ content }: Props) => {

    return (

        <Container>

            <Target id="home" />

            <ContentContainer>

                <TitleContainer>

                    <h1 className="utils__fade-in-center">{content.title}</h1>

                    <p className="utils__fade-in-top">{content.description}</p>

                </TitleContainer>

                <InfoContainer>

                    {
                        content.cards.map((value) => (

                            <Card key={value.id} className="utils__fade-in-right">

                                <ImageContainer>

                                    <Image
                                        src={value.image}
                                        alt={value.title}
                                    />

                                </ImageContainer>

                                <h2>{value.title}</h2>

                                <p>{value.description}</p>

                            </Card>

                        ))
                    }

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}
