import { useLogEvent } from "../hooks/useLogEvent";

export const Rights = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <div className='rights__container'>

            <div className='rights__content-container'>

                <h3 className="utils__fade-in-fast">Copyright © {new Date().getFullYear()} Nidex Logística - Todos los derechos reservados</h3>

                <a
                    className='rights__button utils__fade-in-fast'
                    href="https://www.instagram.com/batata.agencia"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                        handleLogEvent('click_boton_batata');
                    }}
                >

                    <h3>Diseñado y desarrollado por <span>Batata © Agencia Digital</span></h3>

                </a>

            </div>

        </div >

    )
}
